// External dependencies.
import Glide from '@glidejs/glide';

const imageSliders = document.querySelectorAll('.js-block-image-slider__list');

if (imageSliders){
  [].forEach.call(imageSliders, function (imageSlider) {
    let className = '.' + imageSlider.className.replace(/\s/g, '.');

    new Glide(className, {
      type: 'gallery',
      gap: 0
    }).mount();
  });
}